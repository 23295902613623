
import { defineComponent, ref, toRefs, watch } from "vue";
import axios from "axios";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "DeleteVehicle",
  components: {},
  props: {
    vehicleId: {
      type: Number,
      required: false,
      default: 0,
    },
    marca: {
      type: String,
      required: false,
      default: "",
    },
    modelo: {
      type: String,
      required: false,
      default: "",
    },
    version: {
      type: String,
      required: false,
      default: "",
    },
    anio: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["finishDelete"],
  setup(props, { emit }) {
    const deleteVehicleModalRef = ref<null | HTMLElement>(null);
    const marca = toRefs(props).marca;
    const modelo = toRefs(props).modelo;
    const anio = toRefs(props).anio;
    const version = toRefs(props).version;
    const marcaW = ref<any>();
    const modeloW = ref<any>();
    const versionW = ref<any>();
    const anioW = ref<any>();

    const deleteVehicle = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/vehicles/" + props.vehicleId;

        const { data } = await axios.delete(url);
        if (data.success) {
          hideModal(deleteVehicleModalRef.value);
          emit("finishDelete", {
            type: "delete",
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    watch(marca, async (current) => {
      if (current) {
        marcaW.value = current;
      }
    });

    watch(modelo, async (current) => {
      if (current) {
        modeloW.value = current;
      }
    });

    watch(version, async (current) => {
      if (current) {
        versionW.value = current;
      }
    });

    watch(anio, async (current) => {
      if (current) {
        anioW.value = current;
      }
    });

    return {
      deleteVehicleModalRef,
      deleteVehicle,
    };
  },
});
