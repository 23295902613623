
import { defineComponent, ref, onMounted, toRefs, watch } from "vue";
import Datatable from "@/components/vehicles/Vehicles.vue";
import ModalDeleteVehicle from "@/components/vehicles/DeleteVehicle.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import _ from "lodash";
import store from "@/store";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "Vehicles",
  props: {
    agencyId: {
      required: true,
      type: Number,
      default: 0,
    },
    showStatics: {
      required: true,
      type: Boolean,
      default: true,
    },
  },
  components: {
    Datatable,
    ModalDeleteVehicle,
    Field,
  },
  setup(props) {
    const keywords = ref<string>("");
    const tipoRol = ref<string>("");
    const showing = ref<number>(10);
    const nextToShow = ref<number>(0);
    let searchWord = ref<string>("");
    const hasAgencies = ref<boolean>(false);
    const canDelete = ref<boolean>(false);
    const canDetail = ref<boolean>(false);
    const noResults = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const mostrarAgencia = ref<boolean>(true);
    const agencyId = toRefs(props).agencyId;
    const showStatics = toRefs(props).showStatics;
    const route = useRoute();
    const path = computed(() => route.path);
    const headerConfig = ref<any>([
      {
        name: "VIN",
        key: "vin",
        sortable: true,
      },
      {
        name: "Año",
        key: "anio",
        sortable: true,
      },
      {
        name: "Marca",
        key: "marca",
        sortable: true,
      },
      {
        name: "Modelo",
        key: "modelo",
        sortable: true,
      },
    ]);

    const showLoader = ref<boolean>(false);
    const tableData1 = ref<any>([]);
    const links = ref<any>([]);
    const total = ref<number>(0);
    const vehicleId = ref<number>(0);
    const from = ref<number>(0);
    const to = ref<number>(0);
    const type = ref<string>("new");
    const brandsCat = ref<string[]>([]);
    const agenciesCat = ref<string[]>([]);
    const statusCat = ref<string[]>([]);
    const searchByBrand = ref<string>("Marca");
    const searchByStatus = ref<string>("Estatus");
    const searchByAgency = ref<string>("Agencia");
    const userRole = ref<any>();
    const userAgencies = ref<any>();
    const idAgency = ref<any>();
    const marca = ref<any>();
    const modelo = ref<any>();
    const anio = ref<any>();
    const version = ref<any>();
    const agencyVehicles = ref<any>([]);
    const params = ref<any>();

    const finishAction = async (evt) => {
      if (evt.type == "delete") {
        Swal.fire({
          text: "Vehículo eliminado con éxito!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
      tableData1.value.splice(0);
      await getVehicles();
    };

    const deleteVehicle = async (id) => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/vehicles/" + id;

        const { data } = await axios.get(url);

        if (data.success) {
          marca.value = data.data.brand.brand;
          modelo.value = data.data.model.model;
          version.value = data.data.version.version;
          anio.value = data.data.year.year;
          vehicleId.value = id;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getPagination = async (link) => {
      tableData1.value.splice(0);
      let urlProd = link.url;
      if (urlProd.search("seminuevossolana") > 0) {
        urlProd = urlProd.replace("http", "https");
      }
      await getVehicles(urlProd, true);
    };

    watch(
      () => route.params.id,
      async (newId) => {
        idAgency.value = newId;
        getVehicles();
      }
    );

    const getVehicles = async (urlGet = "", isLink = false) => {
      loading.value = true;

      try {
        let url =
          process.env.VUE_APP_API_URL +
          "/api/vehicles?showing=" +
          showing.value;

        if (
          idAgency.value !== "" &&
          idAgency.value !== undefined &&
          !urlGet.includes("agency") &&
          userRole.value.tipo.name != undefined &&
          userRole.value.tipo.name != "Corporativo"
        ) {
          url += "&agency=" + idAgency.value;
        }

        if (route.params.filter !== undefined) {
          // searchByStatus.value = route.params.filter.toString();
          let filter = "";

          filter = "&status=" + route.params.filter;

          // console.log(searchByStatus.value);

          for (let key in statusCat.value) {
            if (statusCat.value[key]["id"] == route.params.filter) {
              searchByStatus.value = statusCat.value[key]["status"];
              break;
            }
          }

          // console.log(searchByStatus.value);

          if (route.params.filter == "7") {
            url =
              process.env.VUE_APP_API_URL +
              "/api/vehicles?onlyviewed=1?showing=" +
              showing.value +
              "&agency=" +
              route.params.agency;
          } else {
            url += "&status=" + route.params.filter;
          }
        }

        if (urlGet != "" && !isLink) {
          url += urlGet;
        }

        if (isLink) {
          url = `${urlGet}&showing=${showing.value}${
            params.value != undefined ? params.value : ""
          }`;
          if (agencyId.value != 0) {
            url = urlGet + "&agency=" + agencyId.value;
          }
        }

        const { data } = await axios.get(url);
        // console.log(data);

        if (data.success) {
          tableData1.value.splice(0);
          agencyVehicles.value.splice(0);
          total.value = 0;
          links.value.splice(0);
          total.value = data.data.total;
          links.value = data.data.links;
          from.value = data.data.from;
          to.value = data.data.to;
          let vehicles = data.data.data;
          if (vehicles.length > 0) {
            noResults.value = false;
            vehicles.forEach((value, ind) => {
              let vehicle = {
                id: value.id,
                vin: value.vin,
                anio: value.year,
                marca: value.brand,
                modelo: value.model,
                agencia: value.agency,
                estatus: value.status,
                version: value.version,
                id_agency: value.id_agency,
                metrics: value.metrics,
              };
              tableData1.value.push(vehicle);
            });
          } else {
            noResults.value = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    };

    const searchItems = _.debounce(async function () {
      loading.value = true;
      if (searchWord.value != "") {
        let url = "&buscar=" + searchWord.value;
        params.value = "&buscar=" + searchWord.value;
        if (searchByBrand.value != "" && searchByBrand.value != "Marca") {
          url += "&brand=" + searchByBrand.value;
        }
        if (searchByStatus.value != "" && searchByStatus.value != "Estatus") {
          url += "&status=" + searchByStatus.value;
        }
        if (searchByAgency.value != "" && searchByAgency.value != "Agencia") {
          url += "&agency=" + searchByAgency.value;
        }
        if (agencyId.value != 0) {
          url += "&agency=" + agencyId.value;
        }
        params.value = url;
        getVehicles(url);
      } else {
        let url = "";
        if (searchByBrand.value != "" && searchByBrand.value != "Marca") {
          url += "&brand=" + searchByBrand.value;
        }
        if (searchByStatus.value != "" && searchByStatus.value != "Estatus") {
          url += "&status=" + searchByStatus.value;
        }
        if (searchByAgency.value != "" && searchByAgency.value != "Agencia") {
          url += "&agency=" + searchByAgency.value;
        }

        if (agencyId.value != 0) {
          url += "&agency=" + agencyId.value;
        }

        if (url != "") {
          params.value = url;
          getVehicles(url);
        } else {
          await getVehicles();
        }
      }
      loading.value = false;
    }, 500);

    const showingSet = async () => {
      tableData1.value.splice(0);
      links.value.splice(0);
      await getVehicles(params.value);
    };

    const downloadReport = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/reporte/agencies";
        if (searchWord.value != "") {
          url += "?type=name&value=" + searchWord.value;
        }
        const { data } = await axios.get(url);
        let csvContent = "data:text/csv;charset=utf-8," + data;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ListadoAgencias.csv");
        link.click();
        //window.open(encodedUri);
      } catch (error) {
        console.log(error);
      }
    };

    const synchronize = async () => {
      try {
        showLoader.value = true;
        await axios
          .get(process.env.VUE_APP_API_URL + "/api/maxipublica/vehicles")
          .then(() => {
            showLoader.value = false;
          });
      } catch (error) {
        showLoader.value = false;
        console.log(error);
      }
    };

    const can = (permissions) => {
      const Permissions = store.getters.getPermissions;
      console.log(Permissions);
      if (_.size(Permissions)) {
        if (Array.isArray(permissions)) {
          let flag = false;
          _.map(permissions, (permission) => {
            if (Permissions.indexOf(permission) !== -1) {
              flag = true;
            }
          });
          return flag;
        } else {
          return Permissions.indexOf(permissions) !== -1;
        }
      }
      return false;
    };

    const getBrandsCat = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/brands";
      try {
        const { data } = await axios.get(url);
        brandsCat.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getStatusCat = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/status";
      try {
        const { data } = await axios.get(url);
        statusCat.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const searchCatsBrand = async (evt) => {
      searchAllCats();
    };

    const searchCatsStatus = async (evt) => {
      searchAllCats();
    };

    const searchCatsAgency = async (evt) => {
      searchAllCats();
    };

    const searchAllCats = async () => {
      let urlCont = "";
      if (searchByBrand.value != "Marca" && searchByStatus.value == "Estatus") {
        urlCont = "&brand=" + searchByBrand.value;
      }
      if (searchByBrand.value == "Marca" && searchByStatus.value != "Estatus") {
        urlCont = "&status=" + searchByStatus.value;
      }
      if (searchByBrand.value != "Marca" && searchByStatus.value != "Estatus") {
        urlCont =
          "&brand=" + searchByBrand.value + "&status=" + searchByStatus.value;
      }
      if (searchByAgency.value != "Agencia") {
        urlCont += "&agency=" + searchByAgency.value;
      }
      if (searchWord.value != "") {
        urlCont += "&buscar=" + searchWord.value;
      }
      if (agencyId.value != 0) {
        urlCont += "&agency=" + agencyId.value;
      }
      params.value = urlCont;
      await getVehicles(urlCont);
    };

    const showNextRow = async (idToShow) => {
      nextToShow.value = idToShow;
    };

    const hideRow = async () => {
      nextToShow.value = 0;
    };

    const getAgencies = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/site/info-agencies";
      try {
        const { data } = await axios.get(url);
        agenciesCat.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      let userRolObj: any = localStorage.getItem("userRole");
      userRole.value = JSON.parse(userRolObj);
      let userAgenciesObj: any = localStorage.getItem("userAgencies");
      userAgencies.value = JSON.parse(userAgenciesObj);
      idAgency.value = localStorage.getItem("agencyIdSelected");

      await getBrandsCat();
      await getStatusCat();

      if (
        path.value == "/vehicles" ||
        path.value.includes("/vehicles-filters")
      ) {
        await getVehicles();
      }

      if (
        userRole.value.tipo.name != undefined &&
        userRole.value.tipo.name == "Corporativo"
      ) {
        headerConfig.value.push({
          name: "Versión",
          key: "version",
          sortable: true,
        });
        headerConfig.value.push({
          name: "Estatus",
          key: "estatus",
          sortable: true,
        });
        if (
          path.value == "/vehicles" ||
          path.value.includes("/vehicles-filters")
        ) {
          headerConfig.value.push({
            name: "Agencia",
            key: "agencia",
            sortable: true,
          });
        }
        headerConfig.value.push({
          name: "Acciones",
          key: "actions",
        });
      } else {
        if (!showStatics.value) {
          headerConfig.value.push({
            name: "Versión",
            key: "version",
            sortable: true,
          });
        }
        headerConfig.value.push({
          name: "Estatus",
          key: "estatus",
          sortable: true,
        });
        headerConfig.value.push({
          name: "Acciones",
          key: "actions",
        });
      }

      tipoRol.value = userRole.value.tipo.name;
      await getAgencies();
    });

    watch(agencyId, async (current) => {
      if (current > 0) {
        let url =
          process.env.VUE_APP_API_URL +
          "/api/vehicles?showing=" +
          showing.value +
          "&agency=" +
          current;
        const { data } = await axios.get(url);
        if (data.success) {
          tableData1.value.splice(0);
          total.value = 0;
          links.value.splice(0);
          total.value = data.data.total;
          links.value = data.data.links;
          from.value = data.data.from;
          to.value = data.data.to;
          let vehicles = data.data.data;
          if (vehicles.length > 0) {
            noResults.value = false;
            mostrarAgencia.value = false;
            vehicles.forEach((value, ind) => {
              let vehicle = {
                id: value.id,
                vin: value.vin,
                anio: value.year,
                marca: value.brand,
                modelo: value.model,
                //agencia: value.agency,
                estatus: value.status,
                version: value.version,
                metrics: value.metrics,
              };
              tableData1.value.push(vehicle);
            });
          } else {
            noResults.value = true;
          }
        }
      }
    });

    return {
      headerConfig,
      loading,
      keywords,
      finishAction,
      getVehicles,
      tableData1,
      links,
      getPagination,
      vehicleId,
      deleteVehicle,
      total,
      searchItems,
      searchWord,
      showing,
      showingSet,
      from,
      to,
      downloadReport,
      hasAgencies,
      type,
      canDelete,
      canDetail,
      can,
      brandsCat,
      getBrandsCat,
      searchCatsBrand,
      searchCatsStatus,
      searchCatsAgency,
      searchByBrand,
      searchByStatus,
      searchByAgency,
      getStatusCat,
      statusCat,
      searchAllCats,
      userRole,
      userAgencies,
      tipoRol,
      idAgency,
      agenciesCat,
      getAgencies,
      noResults,
      marca,
      modelo,
      version,
      anio,
      mostrarAgencia,
      route,
      path,
      nextToShow,
      showNextRow,
      hideRow,
      params,
      synchronize,
      showLoader,
    };
  },
});
